<template>
  <v-menu
    bottom
    left
    offset-y
    max-width="300"
    v-model="show"
    :disabled="disabled"
    :close-on-content-click="false"
    content-class="zg-select-border-radius"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-layout shrink v-on="on" v-bind="attrs">
        <DefaultButton icon :color="color" bgColor="transparent" class="ma-0">
          <v-icon>mdi-plus-circle</v-icon>
        </DefaultButton>
      </v-layout>
    </template>
    <v-card>
      <v-layout column>
        <DefaultMenuDelegate
          @clicked="onClicked(index)"
          v-for="(row, index) in rows"
          :key="index"
          :value="row"
        ></DefaultMenuDelegate>
      </v-layout>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    field: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
      rows: ["Dodaj wpis do swojej karty pacjenta"],
    };
  },
  computed: {
    color() {
      if (this.disabled) {
        return "disabled_item";
      } else {
        return "expressive_green";
      }
    },
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    DefaultMenuDelegate: () =>
      import("@/components/delegates/DefaultMenuDelegate"),
  },
  methods: {
    onClicked(index) {
      this.$emit("clicked", index);
      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-menu__content {
  z-index: 210 !important;
}
.zg-select-border-radius {
  border-radius: 15px !important;
}
</style>